import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BiSearchAlt } from "react-icons/bi";
import { FaFilter } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { FaExchangeAlt, FaUserAlt } from "react-icons/fa";
import { HiOutlineBellAlert, HiOutlineUserCircle } from "react-icons/hi2";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function SearchBar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setisNotificationOpen] = useState(false);
  const [isCustomDropdownOpen, setIsCustomDropdownOpen] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const toggleCustomDropdown = () => {
    setIsCustomDropdownOpen(!isCustomDropdownOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const togglenotification = () => {
    setisNotificationOpen(!isNotificationOpen);
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="flex flex-row justify-center items-center  ">
        <form className="w-[50%]  mt-4 mb-4">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only :text-white"
          >
            Search
          </label>
          <div className=" relative left-[6rem]">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 :text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              onFocus={toggleDropdown}
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
              placeholder="i am looking for ..."
              required=""
            />
            <button
              onClick={toggleDropdown}
              className="flex flex-row items-center gap-2 text-white font-all absolute right-2.5 bottom-2.5 bg-[#cb2229] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 :bg-blue-600 :hover:bg-blue-700 :focus:ring-blue-800"
            >
              Filter <FaFilter />
            </button>
          </div>

          {isDropdownOpen && (
            <div className="shadow-lg drop-shadow-md border border-black  absolute mt-2 w-[50rem] bg-white rounded-md drop-filter lg:left-[425px] lg:top-[4rem] scale-in-ver-top z-10	">
              <div className="py-1">
                {/* // */}
                {/* text  */}
                <div className="flex flex-row items-center lg:gap-[35rem] bg-[#5c5a5a52] p-[10px]">
                  <h1 className="font-all font-semibold text-[20px] font-ld ml-4 text-[#303432] ">
                    Recent Searches{" "}
                  </h1>
                  <RxCross1
                    className="lg:text-[20px] cursor-pointer"
                    onClick={toggleDropdown}
                  />
                </div>

                <div className="flex flex-col mt-[12px]">
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Mobile Accesories
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem]" />
                  </div>
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Mobile Accesories
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem] " />
                  </div>
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Home decoration
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem]" />
                  </div>
                </div>

                {/* <hr className="relative lg:top-[1rem]" /> */}
                {/* // */}
                {/* text  */}
                <div className="flex flex-row items-center lg:gap-[35rem] bg-[#5c5a5a52] p-[10px]">
                  <h1 className="font-all font-semibold text-[20px] font-ld ml-4 text-[#303432] ">
                    Best selling{" "}
                  </h1>
                </div>
                <hr />

                <div className="flex flex-col mt-[12px]">
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Mobile Accesories
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem]" />
                  </div>
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Mobile Accesories
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem] " />
                  </div>
                  <div className="flex flex-row items-center border border-y py-[10px] border-gray-200 gap-4">
                    <BiSearchAlt className="text-[23px] ml-[4rem]" />{" "}
                    <h6 className="font-all font-semibold text-[18px] relative left-4">
                      Home decoration
                    </h6>{" "}
                    <AiOutlineRight className="text-[22px] relative left-[17rem]" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>

        <div className="flex flex-row items-center relative left-[11rem] gap-[13px]">
          <HiOutlineBellAlert
            onClick={togglenotification}
            className="text-[#6f6868] text-[24px] cursor-pointer"
          />
          {isNotificationOpen && (
            <div className="border-[1px] border-black shadow-md drop-shadow-md absolute mt-2 w-[17rem] bg-white rounded-md drop-filter right-0 lg:top-[1rem] scale-in-ver-top z-10	">
              <div className="py-1">
                {/* // */}
                {/* text  */}
                <div className="flex flex-row items-center lg:gap-[6rem] lg:mt-[6px]">
                  <h1 className="font-all font-semibold text-[20px] ml-4 text-[#3b3e3c] ">
                    Notifications
                  </h1>
                  <RxCross1
                    className="lg:text-[20px] cursor-pointer"
                    onClick={togglenotification}
                  />
                </div>
                <hr className="mt-1" />
                <div className="flex flex-col  justify-start">
                  <div className=" bg-[#f7dedf] ml-2 flex flex-row justify-start items-center gap-[21px] mt-4">
                    <div className="image-grid lg:w-[44px]"></div>
                    <span className="font-semibold">
                      1 upcomming event :{" "}
                      <span className="mont-serif lg:text-[12px]">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                      </span>{" "}
                    </span>
                  </div>
                  {/* -- b */}

                  <div className="  bg-[#f7dedf] ml-2 flex flex-row justify-start items-center gap-[21px] mt-2 border-y -black p-[3px]">
                    <div className="image-grid lg:w-[44px]"></div>
                    <span className="font-semibold">
                      1 upcomming event :{" "}
                      <span className="mont-serif lg:text-[12px]">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                      </span>{" "}
                    </span>
                  </div>

                  <div className=" ml-2 flex flex-row justify-start items-center gap-[21px] mt-2 border-y -black p-[3px]">
                    <div className="image-grid lg:w-[44px]"></div>
                    <span className="font-semibold">
                      1 upcomming event :{" "}
                      <span className="mont-serif lg:text-[12px]">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                      </span>{" "}
                    </span>
                  </div>

                  <div className=" bg-[#f7dedf]  ml-2 flex flex-row justify-start items-center gap-[21px] mt-2 border-y -black p-[3px]">
                    <div className="image-grid lg:w-[44px]"></div>
                    <span className="font-semibold">
                      1 upcomming event :{" "}
                      <span className="mont-serif lg:text-[12px]">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                      </span>{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <Link to="/userProfile"> */}
          <HiOutlineUserCircle
            onClick={toggleCustomDropdown}
            className="text-[#404040] text-[28px] cursor-pointer"
          />
          {/* </Link> */}

          {/* Custom Dropdown menu */}
          {isCustomDropdownOpen && (
            <div className="z-10 absolute mt-12 w-max">
              <ul className="py-2 text-sm text-gray-700 shark:text-gray-200 absolute z-10 bg-white shadow-md rounded-md drop-shadow scale-in-ver-top w-max">
                <Link to="/userProfile" onClick={toggleCustomDropdown}>
                  <span className="block px-4 py-2 hover:bg-gray-100  cursor-pointer  shark-hover-bg-gray-600 shark-hover-text-white flex flex-row gap-2 items-center">
                    <FaUserAlt /> My Profile
                  </span>
                </Link>
                <Link to="/update_phone_number" onClick={toggleCustomDropdown}>
                  <span className="block px-4 py-2 hover:bg-gray-100   cursor-pointer shark-hover-bg-gray-600 shark-hover-text-white flex flex-row gap-2 items-center">
                    <FaExchangeAlt /> Change number
                  </span>
                </Link>
                <li>
                  <span
                    className="block px-4 py-2 hover:bg-red-100  cursor-pointer  shark-hover-bg-gray-600 shark-hover-text-white text-red-600 flex flex-row items-center gap-2"
                    onClick={toggleModal}
                  >
                    <BsTrash /> Delete account
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {isModalVisible && (
        <>
          <div
            id="popup-modal"
            tabIndex="-1"
            className="fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow shark:bg-gray-700">
                <button
                  type="button"
                  onClick={toggleModal}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center shark:hover:bg-gray-600 shark:hover:text-white"
                  data-modal-hide="popup-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                  <svg
                    className="mx-auto mb-4 text-gray-400 w-12 h-12 shark:text-gray-200"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 shark:text-gray-400">
                    Are you sure you want to delete this account
                  </h3>
                  <button
                    onClick={toggleModal}
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 shark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                    data-modal-hide="popup-modal"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    onClick={toggleModal}
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 shark:bg-gray-700 shark:text-gray-300 shark:border-gray-500 shark:hover:text-white shark:hover:bg-gray-600 shark:focus:ring-gray-600"
                    data-modal-hide="popup-modal"
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        id="hs-custom-backdrop-modal"
        className={`hs-overlay ${
          isModalOpen ? "hs-overlay-backdrop-open:bg-blue-950/90" : "hidden"
        } w-full h-full fixed top-0 start-0 z-[60] overflow-x-hidden overflow-y-auto pointer-events-none`}
      >
        <div
          className={`hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto`}
        >
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
              <h3 className="font-bold text-gray-800 dark:text-white">
                Modal title
              </h3>
              <button
                type="button"
                className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                onClick={closeModal}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
            <div className="p-4 overflow-y-auto">
              <p className="mt-1 text-gray-800 dark:text-gray-400">
                This is a wider card with supporting text below as a natural
                lead-in to additional content.
              </p>
            </div>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
