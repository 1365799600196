import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { IoIosArrowDown, IoIosArrowUp, IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import Spinner from "../component/Spinner";
const CountrySelect = () => {
  const navigate = useNavigate();
  const [filteredCountries, setFilteredCountries] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [spinner, setspinner] = useState(false);
  const [countryState, setCountryState] = useState({
    loading: false,
    countries: [],
    errorMessage: "",
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };
  //
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
  
    // Filter countries based on the input
    const filtered = countries.filter((country) =>
      country.name.common.toLowerCase().includes(inputValue.toLowerCase())
    );
  
    setFilteredCountries(filtered);
  };
  

  const handlePrint = async (e) => {
    setspinner(true);

    e.preventDefault();

    const selectedCountryData = countries.find(
      (obj) => obj.name.common === selectedCountry
    );

    if (selectedCountryData) {
      const dialingCode = selectedCountryData.idd
        ? selectedCountryData.idd.root + selectedCountryData.idd.suffixes
        : "N/A";

      console.log("Selected Country:", selectedCountry);

      console.log(dialingCode + inputValue);
    }

    const dialingCode = selectedCountryData.idd
      ? selectedCountryData.idd.root + selectedCountryData.idd.suffixes
      : "N/A";
    try {
      console.log("parsing into the api " + dialingCode + inputValue);
      const response = await axios.post(
        "https://inobackend-production.up.railway.app/api/v1/user/register",
        {
          mobileNumber: `${dialingCode + inputValue}`,
        }
      );
      console.log(response);
      setspinner(false);
      const url = `/auth/verifyphone/${dialingCode + inputValue}`;
      navigate(url);
    } catch (error) {
      console.log(error);
    }
  };

  //
  useEffect(() => {
    const fetchData = async () => {
      try {
        // fetch spinner
        setCountryState({
          ...countryState,
          loading: true,
        });

        //  fetch data
        const dataUrl = `https://restcountries.com/v3.1/all`;
        const response = await axios.get(dataUrl);
        setCountryState({
          ...countryState,
          countries: response.data,
          loading: false,
        });
      } catch (error) {
        setCountryState({
          ...countryState,
          loading: false,
          errorMessage: "Sorry, something went wrong",
        });
      }
    };

    fetchData();
  }, []);

  const { loading, errorMessage, countries } = countryState;
  const [selectedCountry, setSelectedCountry] = useState("Pakistan");

  // find selected country data
  const searchSelectedCountry = countries.find(
    (obj) => obj.name.common === selectedCountry
  );

  return (
    <React.Fragment>
      <div>
        <div className="bg-white smd  h-max pb-20 mx-5 mt-40 w-max md:mx-auto l lg:mx-auto rounded-md shadow-md">
          {/* body section */}
          <div>
            {loading ? (
              <div className="flex justify-center items-center h-48">
                <button
                  type="button"
                  className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  <span
                    className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  />
                  Loading
                </button>
              </div>
            ) : (
              <div className="grid justify-center mt-14 mx-10 space-y-10 bg-white">
                <p className="text-center  text-[#738482] font-all font-semibold mt-8 ">
                  Select your country code or enter phone number to continue{" "}
                </p>

                <div className="relative  text-left flex justify-center ">
                  <button
                    onClick={toggleDropdown}
                    className="  flex flex-row items-center justify-center gap-3   py-3 px-4 inline-fex items-ceter gap-x-2 text-sm font-semibold rounded-lg border  bg-gray-400 text-black disabled:opacity-50 disabled:pointer-events-none shark:focus:outline-none shark:focus:ring-1 shark:focus:ring-gray-600 shark:bg-white shark:text-gray-800"
                  >
                    <span className="font-all">
                      {" "}
                      {selectedCountry || "--Select Country--"}
                    </span>
                    {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </button>

                  {isOpen && (
  <ul className="absolute h-96 overflow-scroll z-10 mt-8 w-96 bg-white border rounded-md shadow-md fade-in-left">
    <div className="max-w-sm mx-auto mb-2 sticky top-0">
      <input
        className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        type="search"
        placeholder="Search"
        onChange={handleInputChange}
      />
      <button className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-700 bg-gray-100 border border-gray-300 rounded-r-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
        <IoIosSearch />
      </button>
    </div>

    {(filteredCountries.length > 0 ? filteredCountries : countries).map((item) => (
      <li
        key={uuidv4()}
        onClick={() => handleCountrySelect(item.name.common)}
        className={`flex items-center p-2 cursor-pointer ${
          selectedCountry === item.name.common ? "bg-blue-500 text-white" : ""
        }`}
      >
        <img
          className="w-8 h-8 mr-2"
          src={item.flags && item.flags.png}
          alt={`Flag of ${item.name.common}`}
        />
        <span>{item.name.common}</span>
      </li>
    ))}
  </ul>
)}

                </div>
                <div>
                  {searchSelectedCountry && (
                    <div className="flex space-x-4">
                      <div className="flex items-end border-b-2 border-gray-500 pb-2">
                        <img
                          className="w-8 h-8"
                          src={
                            searchSelectedCountry.flags &&
                            searchSelectedCountry.flags.png
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="h-14 text-xl flex items-end border-b-2 border-gray-500 pb-3 md:text-2xl font-allw">
                          {searchSelectedCountry.idd &&
                            searchSelectedCountry.idd.root}
                          {searchSelectedCountry.idd &&
                            searchSelectedCountry.idd.suffixes}
                        </p>
                      </div>
                      <div>
                        <input
                          type="tel"
                          placeholder="Phone"
                          className="w-full h-14 text-xl border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-white focus:border-b-2 focus:outline-none md:text-2xl"
                          onChange={handleInputChange} // Add this line
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <button
              onClick={handlePrint}
              className="lg:mt-[7rem] xs:mt-[6rem] flex justify-center items-center mx-auto m-auto bg-[#CB2229] text-white rounded-full font-all p-[6px]  w-[18rem]   transform hover:scale-105 duration-500 ease-in-out "
              fdprocessedid="yajzdu"
            >
              {spinner ? (
                <>
                  <span className="flex flex-row gap-2">
                    receiving otp <Spinner />
                  </span>
                </>
              ) : (
                <>get otp</>
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CountrySelect;
