import React, { useState } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";

import "react-phone-input-2/lib/style.css";
import CountrySelect from "../../test/CountrySelect";

export default function VerifyPhone() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const navigate = useNavigate();
  const handleMobileNumberChange = (e) => {
    if (e && e.target) {
      setMobileNumber(e.target.value);
    }
  };

  return (
    <>
      <div className="xs:min-h-screen ">
        <div className=" lg:hidden  --logo flex justify-center xs:mt-[3rem]">
          <img
            src={Logo}
            alt=""
            className="lg:w-[134px] xs:h-[49px] bounce-in-top  "
          />
        </div>
        <div className="flex flex-row justify-start bg-[#cb22290f]  ">
          <div className="xs:hidden item-1 h-screen lg:w-[100vh] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[3rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className=" fade-in-left item-2 bg-[#cb22290f] xs:bg-white w-[50%] flex justify-center xs:w-[100%]">
            <CountrySelect />
          </div>
        </div>
      </div>
    </>
  );
}
// navigate('/');
//
