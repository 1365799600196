import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../../../component/Loader";
import { BsPencil } from "react-icons/bs";
import { fetchBussinessCardDetails ,delete_bussiness_card } from "../../../../../../api/userRegestration/CollectionForm";
import {  MdDeleteOutline } from "react-icons/md";

import EditBussinessCard from "./EditBussinessCard";

export default function ViewCard() {
  const { businessCardId } = useParams();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [deletionMessage, setDeletionMessage] = useState(null);

  const delete_bussinesscard = async () => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to delete this business card?");

      if (!isConfirmed) {
        return;
      }

      const produtdata = {
        businessCardId: businessCardId
      };

      const Product = await delete_bussiness_card(produtdata);
      console.log("user action:", Product);

      if (Product && Product.success) {
        setDeletionMessage("Business card deleted successfully");
        setProductDetails(null);
      }
    } catch (error) {
      console.error("Error in :", error.message);
    }
  };

  useEffect(() => {
    if (!businessCardId) {
      setDeletionMessage("Business card not found. Create a new one.");
      setLoading(false);
      return;
    }

    fetchBussinessCardDetails(businessCardId)
      .then((response) => {
        setLoading(false);
        setProductDetails(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [businessCardId]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const updateProductDetails = (newDetails) => {
    setProductDetails(newDetails);
  };
  // 
  return (
    <div>
      
      {businessCardId == null && undefined ? (
      <div className="deletion-message text-blue-400 font-bold">
        Create a business card to continue.
      </div>
    ) :
      loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) :
      deletionMessage ? (
        <div className="deletion-message text-blue-400 font-bold">{deletionMessage}</div>
      ) :
      
      editMode ? (
        <EditBussinessCard
          businessCardId={businessCardId}
          productDetails={productDetails}
          toggleEditMode={toggleEditMode}
          updateProductDetails={updateProductDetails}
          setProductDetails
        />
      ) : (
        <>
          {/* Render your regular view */}
          <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] h-max">
            <h1 className="fade-in-left font-all text-center font-semibold lg:text-[22px] lg:mt-[2rem] mb-8 flex flex-row justify-center items-center">
              Your Business Card 
            </h1>

            <div className="fade-in-right bg-white drop-c  w-[45%] mx-auto h-max mt-8">
              <h1 className="flex flex-row gap-6 font-all text-left ml-4 font-semibold lg:text-[18px] relative top-[1rem] ">
                name : <span className="font-light">{productDetails.name}</span>
              </h1>

              <div className="flex flex-col py-6">
                <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full">
                        <tbody>
                          <tr className="border-b">
                            <td className="text-sm font-all text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                              Occupation / identification:
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {productDetails.identification}
                            </td>
                          </tr>
                          <tr className="bg-white border-b">
                            <td className="text-sm font-all text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                              Location
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {productDetails.location}
                            </td>
                          </tr>
                          <tr className="border-b">
                            <td className="text-sm font-all text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                              Email id
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {productDetails.email}
                            </td>
                          </tr>
                          <tr className="bg-white border-b">
                            <td className="text-sm font-all text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                              Company Name
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {productDetails.companyName}
                            </td>
                          </tr>
                          <tr className="bg-white border-b">
                            <td className="text-sm font-all text-gray-900 font-semibold px-6 py-4 whitespace-nowrap">
                              Mobile Number
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {productDetails.phoneNumber}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="w-full border-grey-300" />
              <div className="flex flex-row items-center ml-4 gap-8 text-[14px] mt-4">
                
                <div className="b">
                <span className="font-all font-bold ">edit / delete _ business card</span>
                </div>
                <div className="a flex lfex-row gap-3 items-center">
                <BsPencil className="cursor-pointer text-[20px]" onClick={toggleEditMode} />
                <MdDeleteOutline  onClick={delete_bussinesscard} className="cursor-pointer text-red-500 text-[25px]"  />
                </div>

                {/* Toggle edit mode on click */}
              </div>
              <hr className="w-full mt-2 border-grey-300" />
            </div>

            <div className="button-action flex flex-col items-center lg:mt-[2rem] dro-c lg:w-[45%] mx-auto">
              <button className="bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
                Share card
              </button>
              <button className="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                Go back
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
