import { useEffect, useState } from "react";
import {CreateBussinessCards} from "../../../../../../api/userRegestration/CollectionForm"
import Spinner from "../../../../../../component/Spinner";

export default function CreateBussinessCard() {
  const accessToken = localStorage.getItem("token");
  const [loader, setloader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    identification: "",
    location: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    position: "",
    website:"",
    socialLink:""

  });
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  }



  
  const handleSubmit = async (event) => {
    setloader(true)
    event.preventDefault();
    try {
      const produtdata = {
        name:formData.name,
        identification: formData.identification,
        location: formData.location ,
        email: formData.email,
        phoneNumber:formData.phoneNumber,
        companyName: formData.companyName,
        position: formData.position,
        website:formData.website,
        socialLink:formData.socialLink
      };
      const Product = await CreateBussinessCards(produtdata);
      console.log("post:", Product);
    setloader(false)
    window.location.reload()

    } catch (error) {
      console.error("Error in :", error.message);
    }
  };
  return (
    <>
      <div className="bg-layout drop-shadow-md lg:w-[152vh] mb-12 lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row lg:gap-[10%] mt-[3rem] mb-[2rem]">
              {/* 1 */}
              <div className="form-input flex flex-col  w-[47%]">
                <h1 className="font-all font-semibold text-center">
                  Enter Bussiness Card Details
                </h1>
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Full name
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="name"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.name}
                  onChange={handleInputChange}
                  required

                  />
                </div>{" "}
                {/* 2  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[12rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Occupation / Identification
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="identification"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.identification}
                    onChange={handleInputChange}
                    required

                  />
                </div>{" "}
                {/* 3 */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="location"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.location}
                    onChange={handleInputChange}
                    required

                  />
                </div>{" "}
                {/* 4  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Email id
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="email"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    aria-autocomplete="list"
                    value={formData.email}
                    onChange={handleInputChange}
                    required

                  />
                </div>{" "}
                {/* 5  */}
                {/* text area  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Mobile number
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="phoneNumber"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    aria-autocomplete="list"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    required

                  />
                </div>{" "}
              </div>

              {/* 2 */}

              <div className="flex flex-col  ">
                {/* 2-a  */}

                <div className="form lg:mt-8">
                  <div className="coolinput  flex flex-col w-fit-content static w-[25rem] lg:w-[541px]  ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      User Position
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="position"
                      className="input px-2 py-3 text-sm border-[1px] w-[25rem] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={formData.position}
                      onChange={handleInputChange}
                      required

                    />
                  </div>{" "}
                  <div className="coolinput  flex flex-col w-fit-content static w-[25rem] lg:w-[541px]  ">
                    <label
                      htmlFor="input"
                      className=" text text-sm   text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Company name
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="companyName"
                      className="input px-2 py-3 text-sm border-[1px] w-[25rem] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      aria-autocomplete="list"
                      value={formData.companyName}
                      onChange={handleInputChange}
                      required

                    />
                  </div>{" "}
                  <div className="coolinput  flex flex-col w-fit-content static w-[25rem] lg:w-[541px]  ">
                    <label
                      htmlFor="input"
                      className=" text text-sm   text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                       website
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="website"
                      className="input px-2 py-3 text-sm border-[1px] w-[25rem] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      aria-autocomplete="list"
                      value={formData.website}
                      onChange={handleInputChange}
                      required
                    />
                  </div>{" "}
                  <div className="coolinput  flex flex-col w-fit-content static w-[25rem] lg:w-[541px]  ">
                    <label
                      htmlFor="input"
                      className=" text text-sm   text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Social media link
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="socialLink"
                      className="input px-2 py-3 text-sm border-[1px] w-[25rem] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      aria-autocomplete="list"
                      value={formData.socialLink}
                      onChange={handleInputChange}
                      required

                    />
                    
                  </div>{" "}
                </div>
                {/* 2-b  */}
                <div class="button-action flex flex-col items-center mt-[4rem] relative right-[3rem]">
                  <span>
                    <button type="sumit" class=" bg-[#CB2229] flex flex-row justify-center items-center gap-[21px] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
                    
{loader ? <>
  creating card  <Spinner/>


</> : <>

create card

</>  }


                    </button>
                  </span>
                  <a aria-current="page" class="active" href="/wallet">
                    <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                      Go Back
                    </button>
                  </a>
                </div>
              </div>
              {/*  */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
