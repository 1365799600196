import React from "react";
import { TbSpeakerphone } from "react-icons/tb";

function PromotionPopup({
  setShowModal,
  handleButtonClick,
  setnotificationmodal,
}) {
  const handleview = () => {
    setShowModal(false);
    setnotificationmodal(true);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none fade-in-right">
        <div className="">
          {/*content*/}
          <div className="border-2 border-gray-300 drop-shadow-lg rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-3xl font-semibold capitalize font-all ">
                input action
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-red-400  h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <div className="my-4">
                <div class="button-action flex flex-col items-center mt-6">
                  <button
                    onClick={handleButtonClick}
                    class=" bg-[#CB2229] flex flex-row justify-center items-center gap-2 text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative flex flex-row items-center gap-3"
                  >
                    <TbSpeakerphone /> Promote Inputs
                  </button>
                  {/* <Link to="/create_module_promotion" > */}
                  <button
                    onClick={handleview}
                    class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  "
                  >
                    continue without promotion
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PromotionPopup;
